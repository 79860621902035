import React from 'react';
// import logo from './logo.svg';
import './App.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Main from './pages/Main'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Main />} /> 
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
