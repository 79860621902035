import React from 'react';
import Item from './item'
import './index.css';

export default function Index() {
    return <div className="container2">
        <Item date="Jan.13.2022"
            href='https://mirror.xyz/fiplus.eth/cgXXfQTSJO1rmZgX0y48BlxbP7bR7RY6_d-FMrJ5_T4'
            title="L2 Will Rise in 2022 and Cross-chain Bridges Will Become Important Infrastructure">
            Straight to the point, the cross-chain bridge will become an important infrastructure of the DeFi space. The bridge is the bottom layer of cross-chain DEXes, which will not replace the bridge and will be the final form on the user's end. As a result, cross-chain bridges will become the protocol layer in the end...
        </Item>
        <Item date="Mar.24.2022"
            href='https://mirror.xyz/fiplus.eth/XU5PW1-iQksYVoj7nTsw8-NaR1uiXzk2AGP-fEoctpE'
            title="Web 3.0 On-chain Identity Research">
            With the rapid development of Web 3.0, there is a sea of fragmented and anonymous user information that is scattered through different addresses on different blockchains...
        </Item>
        <Item date="" title="Long Web3" />
        <Item date="" title="Long Web3" />
    </div>
}