import React from 'react'
import Header from '../components/header';
import Footer from '../components/footer';
import Container1 from '../components/container1';
import Container2 from '../components/container2';
import ContactUs from '../components/contactUs';
export default function Index(){
    return <div>
        <Header />
        <Container1 /> 
        <Container2 /> 
        <ContactUs />
        <Footer />
    </div>
}