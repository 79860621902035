import React from 'react';
import './index.css';

export default function Index() {
    return <div className="contactUs">
        <div className="text">
            Contact us
        </div>
        <div className="email">
            contact@fiplus.io
        </div>
    </div>
}