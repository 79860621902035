import React from 'react';
import Web3ForAll_svg from '../../assets/images/web3ForAll.svg'
import './index.css'

export default function Index() {
    return <div className="container1">
        <div className="content">
            <div>
                <div className="text">
                    Long Web3
                </div>
                <div className="text">
                    Connect Web2 to Web3
                </div>
                <div className="last_text">
                    - Fi+ Capital
                </div>
            </div>
            <div>
                <img src={Web3ForAll_svg} alt="Web3 For All" />
            </div>
        </div>
    </div>
}