import React from 'react';
import './index.css';

type Props = {
    date: string,
    title: string,
    href?: string,
    children?: string
}
export default function Index(props: Props) {
    return <div className="item">
        <div>
            <div className='item-head'>
                <div className="imgIcon">
                </div>
                <label>
                    {props.date}
                </label>
            </div>
            <div className='text'>
                <p title={props.title}>{props.title}</p>
            </div>
            <div className="lineIcon">
            </div>
            <div className="context">
                <p title={props.children}>{props.children}</p>
            </div>
        </div>

        <div className='readMore'>
            <a href={props.href} target="_blank">Read More</a>
        </div>
    </div>
}